import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {
    Row,
    Col,
    Card,
    CardBody,
    // ModalHeader,
    // ModalBody,
    // ModalFooter,
    // Modal,
    Button,
    Input,
    Label,
    Form,
} from "reactstrap";
import download from "../assets/images/download.png";
import qrcode from "../assets/images/qrcode.png";
import axios from "axios";
import {URL} from "../Apiurl";


function Details() {
    const data = JSON.parse(sessionStorage.getItem('viewdetails'));
    const [carddetails, setcarddetails] = useState([data])
    console.log(carddetails)
    const [viewcard, setviewcard] = useState([])
    console.log(viewcard)
    useEffect(() => {
        setcarddetails(data)
        console.log(data)
        cardDetails()
      }, []);

    const cardDetails =()=> {
        var token = sessionStorage.getItem("token");
        axios.post(URL.getonecards, data, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
          console.log(res.data)
        //   if (res.status === 200) {
            // setcarddetails(res.data.summaryResult)
            setviewcard(res.data.summaryResult)
        //   }
        })
    
      }

    return (
        <div>
            <Box sx={{ display: "flex" }} style={{ background: "#eee" }} className="cardmrg" >
                <Drawer></Drawer>
                <CssBaseline />
                <Box component="main" sx={{ flexGrow: 2, p: 4 }}>
                    {/* <h1 >View Details</h1> */}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row" style={{ padding: "20px" }}>
                                        <div className="col"><h6 className="mt-2" style={{ fontWeight: 600 }}>View Details</h6></div>
                                        <div className="col" >
                                            <NavLink to="/Adddetails"><button className="btn pdf-view-btn" style={{ float: "right" }}>
                                                <i class="fa fa-backward mr-2" aria-hidden="true"></i>Back</button></NavLink>
                                        </div>
                                    </div>
                                    <form style={{ padding: "20px" }}>
                                        <div className="row">
                                            <div className="col">
                                                <Label className="textfont">Summary No :</Label><span className="ml-3">{carddetails.summaryNumber}</span><br />
                                                {/* <Input type="text" placeholdr="Enter Title" value="14J9915472206" className="form-control" name="title" /> */}

                                                <Label className="mt-2 textfont">Shape/cut :</Label><span className="ml-4">{carddetails.shapeCut}</span><br />
                                                {/* <Input type="text" className="form-control" value="Round Brilliant" name="acticlephoto" /> */}

                                                <Label className="mt-2 textfont">Total Est. Wt. :</Label><span className="ml-3">{carddetails.totalEstWt}</span><br />
                                                {/* <Input type="text" placeholdr="Enter Description" value="0.33 Carats" className="form-control mb-3" name="description" /> */}
                                             
                                            </div>
                                            <div className="col">
                                                <Label className="textfont">Color :</Label><span className="ml-3">{carddetails.color}</span><br />
                                                {/* <Input type="text" placeholdr="Enter Title" value="E - F" className="form-control" name="title" /> */}
                                                <Label className="mt-2 textfont">Clarity :</Label><span className="ml-3">{carddetails.clarity}</span><br />
                                                {/* <Input type="text" placeholdr="Enter Address" value="VVS" className="form-control" name="address" /> */}
                                                <div className="row mt-2 ">
                                                    <div className="col-3">
                                                        <Label className="textfont">Comments :</Label>
                                                    </div>
                                                    <div className="col">
                                                        <span >{carddetails.comment}</span><br />

                                                    </div>
                                                </div>
                                                {/* <Input type="text" className="form-control mb-3" value="Grading & Identification of origin as mounting permits. Summary number engraved. OSJDI190" name="acticlephoto" /> */}

                                            </div>
                                        </div>
                                        {/* <Label className="mt-2">Image :</Label>
                          <Input type="file" className="form-control" name="description" /> */}


                                        <div className="row mt-2 ">
                                            <div className="col">
                                            <Label className="mt-2 textfont">Image :</Label><br />
                                                <img src={"http://api.igi-org.in/"+ carddetails.softcopy} style={{ width: "400px" }} />
                                            </div>
                                            <div className="col">
                                                
                                            <Label className="mt-2 textfont">Qr code :</Label><br />
                                                <img src={"http://api.igi-org.in/"+ carddetails.qrcode} style={{ width: "400px" }} />

                                            </div>
                                        </div>
                                        <Label className="mt-3 textfont">Description :</Label>
                                        <textarea type="text" placeholdr="Enter Address" value={carddetails.description} className="form-control" name="address" />


                                    </form>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                </Box>
            </Box>
        </div>
    )
}

export default Details