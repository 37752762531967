const IGI_URL = "http://api.igi-org.in/";
export const URL={
    loginUrl:IGI_URL+"api/admin/igiindia/usersignin",
    getcards:IGI_URL+"api/admin/igiindia/getallSummary",
    getonecards:IGI_URL+"api/admin/igiindia/getSummary",
    addcards:IGI_URL+"api/admin/igiindia/addSummary",
    editcards:IGI_URL+"api/admin/igiindia/editsummary",
    deletecards:IGI_URL+"api/admin/igiindia/removesummary",
    getadmins:IGI_URL+"api/admin/igiindia/getalladminusers",
    addadmin:IGI_URL+"api/admin/igiindia/addadminuser",
    editadmin:IGI_URL+"api/admin/igiindia/editadminuser",
    deleteadmin:IGI_URL+"api/admin/igiindia/removeadminuser",
    dashboard:IGI_URL+"api/admin/dashboard/getelemenst",
    adminprofile:IGI_URL+"api/admin/igiindia/get-profile",
    admineditprofile:IGI_URL+"api/admin/igiindia/get-profile",
    changepassword:IGI_URL+"api/admin/igiindia/changepassword",
    getdetails:IGI_URL+"api/user/summarydetails/getdetails",
    getsearchdetails:IGI_URL+"api/user/summarydetails/getStringUserSummaryDetails",
    getsetdata:IGI_URL+"api/admin/setting/getset",
    updatesetdata:IGI_URL+"api/admin/setting/editset",
    a4sizeshhet:IGI_URL+"api/a4sizeshhet",

}