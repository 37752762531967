import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Header from "./Header";
import logo2 from "../assets/images/logo2.png";
import download from "../assets/images/download.png";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import "../assets2/css/style.css";
import wetrain from "../assets2/icon/we_train_best_bg.jpg";
import Footer from "../Components/Footer";
import Header2 from "../Components/Header2";
import whitelogo from "../assets/images/loading.png";
import video0 from "../assets/images/video0.mp4";
import video2 from "../assets/images/vido2.mp4";
import video3 from "../assets/images/video3.mp4";
import video4 from "../assets/images/video4.mp4";
import video5 from "../assets/images/video5.mp4";
import video6 from "../assets/images/video6.mp4";
import "../Css/zzz.css";
import axios from "axios";
import { URL } from "../Apiurl";
import logo from "../assets/images/logo_IGI.png";
import { saveAs } from 'file-saver';
// import error1 from "../assets/images/under_maintenance.png";
import error1 from "../assets/images/error2.png";


function Main2() {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [webshow, setWebshow] = useState(true);
    const [show2, setShow2] = useState(false);
    const [getdata, setgetdata] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [spinner, setSpinner] = useState(true);
    const [sticky, setSticky] = useState("");
    const [visible, setvisible] = useState("");
    const [lefts, setlefts] = useState("");

    // const [getdata, setgetdata] = useState([]);
    // const [spinner, setSpinner] = useState(false);

    const [open, setOpen] = useState(false);
    const [form, setform] = useState('');


    const handlechange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        console.log(e.target.value);
        setform(e.target.value);
    }


    useEffect(() => {

        getAlldetails(id);
        setSpinner(true);
        setTimeout(() => {
            setSpinner(false);
        }, 5000);


        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);


    const isSticky = () => {
        // const clik=onClick
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 250 ? "logo-sm" : "";
        setSticky(stickyClass);
        console.log(stickyClass);
        const visible = scrollTop >= 250 ? "" : "-800px";
        // const leftside = clik >= 250 ? "" : "slideLeft";
        // setlefts(leftside)
        setvisible(visible);
    };
    const Ishide = () => {
        // const clik=onClick
        const visible = "-800px";
        // const leftside = clik >= 250 ? "" : "slideLeft";
        // setlefts(leftside)
        setvisible(visible);
    };
    const classes = `${sticky}`;

    const getAlldetails = (id) => {
        var paras = {
            sumid: id
        };

        axios.post(URL.getdetails, paras).then((res) => {
            if (res.status === 200) {
                console.log(res.data.summaryResult);
                setgetdata(res.data.summaryResult);
                setWebshow(res.data.getSetting.isUnderMaintanance);
                console.log(webshow);
            }
        },

        );
    }

    const getsearchdetails = (id) => {
        var paras = {
            sumid: id
        };

        axios.post(URL.getsearchdetails, paras).then((res) => {
            if (res.status === 200) {
                console.log(res.data.summaryResult)
                setgetdata(res.data.summaryResult)

            }
        },

        );
    }

    const dataSubmit = () => {

        // setgetdata(d)
        // sessionStorage.setItem('dataId', JSON.stringify(d));
        console.log(form);
        setSpinner(true);
        // getAlldetails(form)
        getsearchdetails(form)

        // fetch("https://api.imgflip.com/get_memes")
        //   .then((res) => res.json())
        //   .then((data) => setMemes(data));

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    }


    const downloadImage = () => {
        saveAs(URL+ getdata.softcopy) // Put your image url here.
    }
    const displaymaintenceFunction = () => {
        return (<div>
            <div className='d-flex justify-content-center'>
                <img src={error1} className="errorimg"/>

            </div>

        </div>
        )

    }

    const displayFunction = () => {
        return (
            <>
                <header className={classes}>
                    <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light">

                        <a class="navbar-brand" href="#"><img src={logo}
                            alt="International Gemological Institute - IGI Logo" class="logo_img" /></a>


                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
                            aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="navbar-collapse collapse" id="navbarsExampleDefault">

                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        ABOUT US
                                    </a>
                                    <div class="dropdown-menu collapse" aria-labelledby="navbarDropdown" >
                                        <ul>
                                            <li class="dropdown-item"><a href="#" >About
                                                IGI</a></li>
                                            <li class="dropdown-item"><a href="#">Press Room</a>
                                            </li>
                                            <li class="dropdown-item"><a href="#" >Timeline</a></li>
                                            <li class="dropdown-item"><a href="#" >Affiliations</a></li>
                                            <li class="dropdown-item"><a href="#" >Importance of Certification</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        REPORTS
                                    </a>
                                    <div class="dropdown-menu collapse" aria-labelledby="navbarDropdown" >
                                        <ul>
                                            <li class="dropdown-item"><a href="#" target="">Our
                                                Expertise</a></li>
                                            <li class="dropdown-item dropdown dropright"><a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">How to Read a Report</a>
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul>
                                                        <li class="dropdown-item"><a href="#" target="">Natural Diamond Report</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">LG
                                                            Report</a>
                                                        </li></ul>
                                                </div>
                                            </li>
                                            <li class="dropdown-item dropdown dropright"><a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Diamond Reports</a>
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul>
                                                        <li class="dropdown-item"><a href="#" target="">Diamond</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Laboratory Grown</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Hearts &amp; Arrows</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Colored Diamonds</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Grading Process</a>
                                                        </li></ul>
                                                </div>
                                            </li>
                                            <li class="dropdown-item"><a href="#" target="">Jewelry Report</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Colored
                                                Stone Report</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Screening Services</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Sorting Services</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Security
                                                Seal</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Laserscribe</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Verify Your Report</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        EDUCATION
                                    </a>
                                    <div class="dropdown-menu collapse" aria-labelledby="navbarDropdown" >
                                        <ul>
                                            <li class="dropdown-item"><a href="#" target="">Gemological Education</a></li>
                                            <li class="dropdown-item"><a href="#" target=""></a></li>
                                            <li class="dropdown-item dropdown dropright"><a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Courses</a>
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul>
                                                        <li class="dropdown-item"><a href="#" target="">Polished Diamond Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Rough Diamond Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Colored
                                                            Stones Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Pearl Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Jewelry
                                                            Design Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Jewelry Cad Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Prof. Jewelry Design -
                                                            Matrix Course </a>
                                                        </li><li class="dropdown-item"><a href="#" target="">CorelDraw Design Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">JewelPad
                                                            Design Course</a>
                                                        </li><li class="dropdown-item"><a href="#" target="">Retail
                                                            Support Program</a>
                                                        </li></ul>
                                                </div>
                                            </li>
                                            <li class="dropdown-item"><a href="#" target="">Course Schedules</a></li>
                                            <li class="dropdown-item"><a href="#" target="">E-Learning</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Tuition
                                                Fees</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Enrollment Form</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Enquiry
                                                Form</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Gemstone Articles</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Retail
                                                Support</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Learn with Videos</a></li>
                                            <li class="dropdown-item"><a href="#" target="">Verify a Diploma</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item ">
                                    <a class="nav-link " href="#">
                                        Gemblog
                                    </a>
                                </li>
                                <li class="nav-item ">
                                    <a class="nav-link " href="#">
                                        SHOWS
                                    </a>
                                </li>
                                <li class="nav-item ">
                                    <a class="nav-link " href="#">
                                        Consumer Services
                                    </a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        CONTACT
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <ul>
                                            <li class="dropdown-item"><a href="#" target="">Contact</a>
                                            </li>
                                            <li class="dropdown-item"><a href="#" target="">Enquiry
                                                Form</a></li>
                                        </ul>
                                    </div>
                                </li>
                                {/* <!-- <li class="nav-item dropdown dropdown_men d-none">
<a class="nav-link" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true"
aria-expanded="false"><img src="https://www.igi.org/assets/images/language-1.svg" alt=""
width="20"/></a>
</li> --> */}

                                <li class="nav-item dropdown dropdown_men">
                                    <a class="nav-link dropdown-toggle p-0" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select Language">
                                        <img src="https://igi.org.in/icon/en-lang.png" alt="" width="40" />
                                    </a>

                                    <div class="dropdown-menu p-0 drop-lang collapse" aria-labelledby="navbarDropdown" >

                                        <select id="lang_switcher" name="lang_switcher" class="lang-dropdown" size="6">
                                            <option value="en" selected="selected">English</option>
                                            <option value="ch">简体中文</option>
                                            <option value="tc">繁中文</option>
                                            <option value="it">Italian</option>
                                            <option value="ar">Arabic</option>
                                        </select>
                                    </div>
                                </li>

                                <li class="nav-item dropdown dropdown_men">

                                    <a class="nav-link ghost_btn" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Verify your Report</a>

                                    <div class="dropdown-menu dropdown-menu-right p-0 menu-vr-form" aria-labelledby="navbarDropdown">
                                        <form class="form-inline"
                                        //  action="/getreports" method="post"
                                        >
                                            <div class="form-group mr-sm-3">
                                                <input type="text" class="form-control" placeholder="Enter Report No." name="sumid" onChange={(e) => { handlechange(e); }} required />
                                            </div>
                                            <button type="button" onClick={dataSubmit} class="btn btn-info"><i class="fa fa-search" aria-hidden="true"></i></button>
                                        </form>
                                    </div>
                                </li>

                                <li class="nav-item dropdown menu-qr">
                                    <a class="nav-link" href="#">
                                        <img src="https://www.igi.org/assets/images/qr-code-b.svg" alt="QR Code" class="qr-img" width="22" />
                                    </a>
                                </li>

                            </ul>

                        </div>

                    </nav>

                </header>


                <section class="content_main">
                    <div class="container-fluid">
                        <div class="row">


                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">


                                <div class="row">

                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 order-md-first hide-error">
                                        <div class="footer-logo mb-20 ">
                                            <img id="logo_url" src={logo2}
                                                class="img-fluid" alt="IGI logo" />
                                        </div>
                                        <div class="table-responsive left_summary ">
                                            <div class="card border-none" style={{ border: "none" }}>
                                                <div class="card-body p-0">
                                                    <p class="mb-5">
                                                        <strong>Diamond Report Summary</strong>
                                                    </p>
                                                    <table class="table-360-report">
                                                        <tbody>
                                                            <tr>
                                                                <td width="50%" valign="top">Summary No</td>
                                                                <td align="left" width="50%">
                                                                    <b>
                                                                        {getdata.summaryNumber}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" valign="top">Description</td>
                                                                <td align="left" width="50%">
                                                                    <b>
                                                                        {getdata.description}
                                                                    </b>
                                                                </td>
                                                            </tr>

                                                            <br />


                                                            <tr>
                                                                <td width="50%" valign="top">Shape/cut</td>
                                                                <td align="left" width="50%">
                                                                    <b>
                                                                        {getdata.shapeCut}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" valign="top">Total Est. Wt.</td>
                                                                <td align="left" width="50%">
                                                                    <b>
                                                                        {getdata.totalEstWt}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" valign="top">Color</td>
                                                                <td align="left" width="50%">
                                                                    <b>
                                                                        {getdata.color}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" valign="top">Clarity</td>
                                                                <td align="left" width="50%">
                                                                    <b>
                                                                        {getdata.clarity}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" valign="top">Comments</td>
                                                                <td align="left" width="50%">
                                                                    <b>{getdata.comment}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" valign="top"></td>
                                                                {/* <td align="left" width="50%">
                                                                    <b>
                                                                        OSJDI190
                                                                    </b>
                                                                </td> */}
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        {/* <!-- <div class="report-thumb">

                        <div class="left-report-pdf-area mt-20">
                            <div class="pdf-download-btn view_pdf"></div>
                        </div>

                    </div> --> */}

                                    </div>




                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-first gemologocal_education p-0">
                                        <div class="pdf-report report-360-tab">
                                            <ul class="nav nav-tabs justify-content-center">

                                                <li class="nav-item ReportResult">
                                                    <a class="nav-link active" data-toggle="tab" href="#ReportResult">REPORT</a>
                                                </li>

                                                <li class="nav-item">
                                                    <a class="nav-link" data-toggle="tab" href="#The4cs">Forging Your
                                                        Jewelry</a>
                                                </li>

                                                <li class="nav-item">
                                                    <a class="nav-link" data-toggle="tab" href="#LearnwithVideos">Learn with
                                                        Videos</a>
                                                </li>
                                                {/* <li class="nav-item" >
                                        <a class="nav-link" data-toggle="tab" href="#ForgingYourJewelry"></a>
                                    </li> */}


                                                {/* <li class="nav-item" hidden>
                                        <a class="nav-link" data-toggle="tab" href="#LightPerfomance">LIGHT
                                            PERFORMANCE</a>
                                    </li>
                                    <li class="nav-item" hidden>
                                        <a class="nav-link" data-toggle="tab" href="#hna">Hearts & Arrows Diamonds</a>
                                    </li>





                                    <li class="nav-item d-none">
                                        <a class="nav-link" data-toggle="tab" href="#D360report">360o View</a>
                                    </li>
                                    <li class="nav-item" hidden>
                                        <a class="nav-link" data-toggle="tab" href="#LearnwithVideos"></a>
                                    </li> */}
                                            </ul>

                                            <div class="tab-content ">



                                                <div class="tab-pane container active p-0" id="ReportResult">
                                                    {/* <!-- <a data-fancybox="gallery5" href="https://www.igi.org/assets/images/360/white-img.jpg" data-thumbs="{&quot;autoStart&quot;:true}" class="middle_pdf_preview_link preview-img-size"> --> */}

                                                    <a class="btn pdf-view-btn" style={{color:"white"}} data-toggle="modal"
                                                        onClick={handleShow}>
                                                        Zoom <i class="fa fa-search-plus" aria-hidden="true"></i>
                                                    </a>


                                                    {/* <!-- <a href="#" class="middle_pdf_preview_link preview-img-size"> --> */}
                                                    <img src={"http://api.igi-org.in/"+ getdata.softcopy}
                                                        class="hover-shadow cursor img-fluid middle_pdf_preview" />
                                                    <div class="middle_pdf_preview_embed_iframe">

                                                    </div>

                                                    {/* <!-- </a> --> */}

                                                    <div class="pdf-download-btn view_pdf mt-3 ">
                                                        <div className='row'>
                                                       <div className='col-sm-3'>
                                                       <a href="#"  style={{width:"100%"}}>
                                                            <a type="button" onClick={downloadImage} target="_blank" class="theme_btn" value="" style={{ border: "none" }} >Download PDF</a>
                                                            {/* <input type="submit" href={"http://103.171.181.73:5011/" + getdata.image} target="_blank" class="theme_btn bg-white" value="Download PDF" style={{ border: "none" }} /> */}
                                                        </a>
                                                       </div>
                                                       <div className='col-sm-3'>
                                                       <a  style={{width:"100%"}} class="a2a_dd" target='_blank' href={"https://www.addtoany.com/share#url=http%3A%2F%2Figi-org.in%2Freports%2Fverify-your-report%2F" + id}>
                                                            <i class="fa fa-share-alt" aria-hidden="true"></i>
                                                            Share
                                                        </a>
                                                       </div>
                                                        </div>
                                                        {/* <a href="#" class="terms_and_conditions" target="_blank">Terms &
                                        Conditions</a>
                                    <a href="#" class="terms_and_conditions" target="_blank">Glossary</a>
                                    */}
                                                    </div>

                                                </div>

                                                <div class="tab-pane container" id="The4cs">
                                                    <div class="row" style={{ textAlign: "justify" }}>


                                                        <p>Your jewelry was conceived and fashioned by dedicated professionals from
                                                            start to finish. </p>

                                                        <ul class="mb-15">
                                                            <li>Conception: A design was drawn using hand-sketches or CAD software</li>
                                                            <li>Creation: A hardened mold was produced to accomodate your jewelrys
                                                                forging</li>
                                                            <li>Casting: The molten precious-metal alloy was delivered into that mold
                                                                under consistent pressure, promoting bonding, density and strength</li>
                                                        </ul>

                                                        <p>Your jewelry was carefully removed, refined and cleaned, undergoing a
                                                            rigorous dual-process of fine-tuning and polish prior to setting, whereafter
                                                            it was thoroughly inspected for durability and quality, as well as beauty.
                                                        </p>

                                                        <img src="https://igi.org.in/icon/Forging-Your-Jewelry-new-image-web.jpg" width="70%" class="img-fluid mb-20 mx-auto d-block" /><br />

                                                        <h4 class="mt-10">The process of jewelry-making</h4>

                                                        <p>From artistic collaboration and direction to forging and cooling to detailed
                                                            clean-casting and pre-polish. From secure, precision stonesetting to hours
                                                            of final polish, every step was deliberate and meticulous.</p>

                                                        <h4>Conception</h4>

                                                        <p>Your piece was designed by visionaries. It may have been an artist using
                                                            hand-sketches or a gemology graduate using modern CAD software. Professional
                                                            jewelry designers must command both the basics and complexities of jewelry
                                                            creation to perfectly balance the primary components of aesthetics,
                                                            durability and cost.</p>

                                                        <img src="https://igi.org.in/icon/Conception.jpg" class="img-fluid mb-20" />

                                                        <h4>Creation</h4>

                                                        <p>An exact replica of your jewelrys mounting was created in modeling wax.
                                                            Whether hand-carved or rendered with high-precision 3D printers, that
                                                            replica was refined to become a twin of your precious item. That twin was
                                                            placed into a casting flask and encased in jewelry-quality investment. The
                                                            investment hardened to form a perfect negative-replica mold, suitable for
                                                            casting. Its job finished, the wax was superheated and removed with vacuum
                                                            technology.</p>

                                                        <div class="row">
                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6">
                                                                <img src="https://igi.org.in/icon/forging-jewelry-creation-1.jpg" class="img-fluid mb-20" />
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6">
                                                                <img src="https://igi.org.in/icon/forging-jewelry-creation-2.jpg" class="img-fluid mb-20" />
                                                            </div>
                                                        </div>

                                                        <h4>Casting</h4>

                                                        <p>Your mold was taken to the foundry and your precious metal was brought to its
                                                            melting point in a crucible. The metal alloy was delivered to the mold in a
                                                            casting centrifuge with high-pressure inert gas, forcing your alloy into sub
                                                            surface voids and facilitating diffusional bonding to promote density,
                                                            strength and durability.</p>

                                                        <img src="https://igi.org.in/icon/forging-jewelry-casting.jpg" class="img-fluid d-block mx-auto mb-20" />

                                                        <h4>Cleaning</h4>

                                                        <p>When finished and cooled your jewelry was carefully removed from the mold. It
                                                            underwent the rigorous dual-process of clean-casting, where excess material
                                                            was removed, and pre-polish, where the piece was thoroughly inspected and
                                                            verified for fundamental quality and integrity.</p>

                                                        <img src="https://igi.org.in/icon/cleaning.jpg" class="img-fluid mb-20" />

                                                        <h4>Culmination</h4>

                                                        <p>Stone-setting and polishing brought your finished jewelry to life. The setter
                                                            meticulously balanced, seated and secured your gemstones, and the polisher
                                                            brought all details of finish to culmination. </p>

                                                        <p>From conception to creation, a talented array of professional specialists
                                                            took deliberate and meticulous steps in the careful forging of your jewelry.
                                                        </p>

                                                        <img src="https://igi.org.in/icon/culmination.jpg" class="img-fluid" />



                                                    </div>
                                                </div>
                                                <div class="tab-pane container fade text-left lwv-tab" id="LearnwithVideos" >

                                                    <div class="row">

                                                        <div
                                                            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6 mb-20">
                                                            <video id="security_seal_video"
                                                                poster="http://www.igi.org/assets/images/360/carat-weight-poster.jpg"
                                                                controls preload="none">
                                                                <source
                                                                    src={video0} />
                                                            </video>

                                                            <p>Diamond weight is expressed in carats. 1.00 carat equals 200
                                                                milligrams. Diamonds under 1.00 carat are expressed in ‘points.
                                                                That means a 0.90 carat diamond is said to weigh “90 points.” You
                                                                should know that two diamonds which weigh the same don’t always look
                                                                the same size. To see examples, click above and Learn With Videos.”
                                                                You may also consult your jewelry professional, or contact us
                                                                directly at
                                                                <a href="https://www.igi.org/" target="_blank">igi.org</a>.
                                                            </p>
                                                        </div>

                                                        <div
                                                            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6 mb-20">
                                                            <video id="security_seal_video" poster="https://www.igi.org/assets/images/360/02-color-200920-poster.jpg" controls preload="none">
                                                                <source src={video2} />
                                                            </video>

                                                            <p>A diamond’s color is caused by chemicals in the earth where the
                                                                diamond formed. The most common color is yellow, and most diamonds
                                                                are graded on a scale ranging from D, which is most colorless, to Z,
                                                                which is light yellow or brown. To see examples, click above and
                                                                “Learn With Videos.” You may also consult your jewelry professional,
                                                                or contact us directly at <a href="https://www.igi.org/"
                                                                    target="_blank">igi.org</a>.</p>

                                                        </div>
                                                    </div>

                                                    <div class="row">


                                                        <div
                                                            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6 mb-20">
                                                            <video id="security_seal_video"
                                                                poster="http://www.igi.org/assets/images/360/03-clarity-200922.jpg"
                                                                controls preload="none">
                                                                <source
                                                                    src={video3} />
                                                            </video>
                                                            <p>Clarity evaluates a gemstones internal and external
                                                                clarity-characteristics. Internal characteristics are classified as
                                                                inclusions. External characteristics are classified as blemishes. A
                                                                gemologist analyzes clarity by looking down through the top of the
                                                                stone at 10-power magnification. To see examples, click above and
                                                                “Learn With Videos.” You may also consult your jewelry professional,
                                                                or contact us directly at <a href="https://www.igi.org/"
                                                                    target="_blank">igi.org</a>.</p>
                                                        </div>

                                                        <div
                                                            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6 mb-20">
                                                            <video id="security_seal_video"
                                                                poster="http://www.igi.org/assets/images/360/04-cut-200926-poster.jpg"
                                                                controls preload="none">
                                                                <source
                                                                    src={video4} />
                                                            </video>
                                                            <p>The cut of a diamond refers to everything man has done to convert it
                                                                from a rough diamond crystal into a finished gemstone capable of
                                                                producing brightness, fire and scintillation. More than any other
                                                                C the way a diamond is cut determines how big, bright and lively
                                                                it appears. To see examples, click above and “Learn With Videos.
                                                                You may also consult your jewelry professional, or contact us
                                                                directly at <a href="https://www.igi.org/"
                                                                    target="_blank">igi.org</a>.</p>
                                                        </div>

                                                    </div>

                                                    <div class="row">

                                                        <div
                                                            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6 mb-20">
                                                            <video id="security_seal_video"
                                                                poster="https://www.igi.org/assets/images/360/Lab-Grown-diamond-poster.jpg"
                                                                controls preload="none">
                                                                <source
                                                                    src={video5} />
                                                            </video>

                                                            <p>Lab-Grown Diamonds are identical to natural diamonds, with the same
                                                                hardness, refractive index, and sparkle. They are graded using the
                                                                same 4Cs. The only difference is they are not billions of years old.
                                                                They are grown in factories using one of two sophisticated methods.
                                                                To see examples, click above and "Learn With Videos." You may also
                                                                consult your jewelry professional, or contact us directly at <a
                                                                    href="https://www.igi.org/" target="_blank">igi.org</a>.</p>
                                                        </div>

                                                        <div
                                                            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6 mb-20">
                                                            <video id="security_seal_video"
                                                                poster="https://www.igi.org/assets/images/360/06-IGI-explains-Confidence.jpg"
                                                                controls>
                                                                <source
                                                                    src={video6} />
                                                            </video>

                                                            <p>Have confidence in your diamond, gem and jewelry purchases with IGI.
                                                                For more information consult your jewelry professional or contact us
                                                                directly at <a href="%27.base_url().%27contact-us/en"
                                                                    target="_blank">igi.org</a>.</p>
                                                        </div>

                                                    </div>



                                                </div>
                                                <div class="tab-pane container fade text-left lwv-tab" id="ForgingYourJewelry"
                                                    hidden>

                                                    <p>Your jewelry was conceived and fashioned by dedicated professionals from
                                                        start to finish. </p>

                                                    <ul class="mb-15">
                                                        <li>Conception: A design was drawn using hand-sketches or CAD software</li>
                                                        <li>Creation: A hardened mold was produced to accomodate your jewelrys
                                                            forging</li>
                                                        <li>Casting: The molten precious-metal alloy was delivered into that mold
                                                            under consistent pressure, promoting bonding, density and strength</li>
                                                    </ul>

                                                    <p>Your jewelry was carefully removed, refined and cleaned, undergoing a
                                                        rigorous dual-process of fine-tuning and polish prior to setting, whereafter
                                                        it was thoroughly inspected for durability and quality, as well as beauty.
                                                    </p>

                                                    <img src="../../icon/Forging-Your-Jewelry-new-image-web.jpg"
                                                        width="70%" class="img-fluid mb-20 mx-auto d-block" /><br />

                                                    <h4 class="mt-10">The process of jewelry-making</h4>

                                                    <p>From artistic collaboration and direction to forging and cooling to detailed
                                                        clean-casting and pre-polish. From secure, precision stonesetting to hours
                                                        of final polish, every step was deliberate and meticulous.</p>

                                                    <h4>Conception</h4>

                                                    <p>Your piece was designed by visionaries. It may have been an artist using
                                                        hand-sketches or a gemology graduate using modern CAD software. Professional
                                                        jewelry designers must command both the basics and complexities of jewelry
                                                        creation to perfectly balance the primary components of aesthetics,
                                                        durability and cost.</p>

                                                    <img src="../../icon/Conception.jpg" class="img-fluid mb-20" />

                                                    <h4>Creation</h4>

                                                    <p>An exact replica of your jewelrys mounting was created in modeling wax.
                                                        Whether hand-carved or rendered with high-precision 3D printers, that
                                                        replica was refined to become a twin of your precious item. That twin was
                                                        placed into a casting flask and encased in jewelry-quality investment. The
                                                        investment hardened to form a perfect negative-replica mold, suitable for
                                                        casting. Its job finished, the wax was superheated and removed with vacuum
                                                        technology.</p>

                                                    <div class="row">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6">
                                                            <img src="../../icon/forging-jewelry-creation-1.jpg"
                                                                class="img-fluid mb-20" />
                                                        </div>

                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 normal-grid-lg-6">
                                                            <img src="../../icon/forging-jewelry-creation-2.jpg"
                                                                class="img-fluid mb-20" />
                                                        </div>
                                                    </div>

                                                    <h4>Casting</h4>

                                                    <p>Your mold was taken to the foundry and your precious metal was brought to its
                                                        melting point in a crucible. The metal alloy was delivered to the mold in a
                                                        casting centrifuge with high-pressure inert gas, forcing your alloy into sub
                                                        surface voids and facilitating diffusional bonding to promote density,
                                                        strength and durability.</p>

                                                    <img src="../../icon/forging-jewelry-casting.jpg"
                                                        class="img-fluid d-block mx-auto mb-20" />

                                                    <h4>Cleaning</h4>

                                                    <p>When finished and cooled your jewelry was carefully removed from the mold. It
                                                        underwent the rigorous dual-process of clean-casting, where excess material
                                                        was removed, and pre-polish, where the piece was thoroughly inspected and
                                                        verified for fundamental quality and integrity.</p>

                                                    <img src="../../icon/cleaning.jpg" class="img-fluid mb-20" />

                                                    <h4>Culmination</h4>

                                                    <p>Stone-setting and polishing brought your finished jewelry to life. The setter
                                                        meticulously balanced, seated and secured your gemstones, and the polisher
                                                        brought all details of finish to culmination. </p>

                                                    <p>From conception to creation, a talented array of professional specialists
                                                        took deliberate and meticulous steps in the careful forging of your jewelry.
                                                    </p>

                                                    <img src="../../icon/culmination.jpg" class="img-fluid" />


                                                </div>






                                                <div class="tab-pane container fade" id="LightPerfomance" hidden>

                                                    <div class="row mb-30">
                                                        <div class="col-12" id="LPLoadingarea" hidden>
                                                            <img src="../../icon/igi-report-loading.gif"
                                                                class="img-fluid" />
                                                        </div>
                                                        <div class="col-12" id="LParea">

                                                            <ul class="nav nav-tabs justify-content-center">
                                                                <li class="nav-item">
                                                                    <a class="nav-link active" data-toggle="tab"
                                                                        href="#LightResult">RESULT</a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" data-toggle="tab"
                                                                        href="#Brilliance">BRILLIANCE</a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" data-toggle="tab"
                                                                        href="#Brightness">BRIGHTNESS</a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" data-toggle="tab" href="#Fire">FIRE</a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" data-toggle="tab"
                                                                        href="#OpticalSymmetry">OPTICAL SYMMETRY</a>
                                                                </li>
                                                            </ul>

                                                            <div class="tab-content">


                                                                <div class="tab-pane container active" id="LightResult">
                                                                    <div class="row">
                                                                        <h4 class="report-sub-heading">Light Performance Result</h4>
                                                                        <img src="../../icon/report_smalli.jpg"
                                                                            class="img-fluid" />
                                                                    </div>
                                                                </div>

                                                                <div class="tab-pane container fade" id="Brilliance">
                                                                    <div class="row">
                                                                        <h4 class="report-sub-heading">Brilliance</h4>
                                                                        <div class="vdo-wrap">
                                                                            <video width="100%" id="video-1" class="vidoes"
                                                                                controls="" preload="none">
                                                                                <source
                                                                                    src="https://www.igi.org/lr/video/389987516-Office-01-1-Brilliance.mp4"
                                                                                    type="video/mp4" />
                                                                            </video>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="tab-pane container fade" id="Brightness">
                                                                    <div class="row">
                                                                        <h4 class="report-sub-heading">Brightness</h4>
                                                                        <div class="vdo-wrap">
                                                                            <video width="100%" id="video-2" class="vidoes"
                                                                                controls="" preload="none">
                                                                                <source
                                                                                    src="https://www.igi.org/lr/video/389987516-Office-01-Brightness.mp4"
                                                                                    type="video/mp4" />
                                                                            </video>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="tab-pane container fade" id="Fire">
                                                                    <div class="row">
                                                                        <h4 class="report-sub-heading">Fire</h4>
                                                                        <div class="vdo-wrap">
                                                                            <video width="100%" id="video-3" class="vidoes"
                                                                                controls="" preload="none">
                                                                                <source
                                                                                    src="https://www.igi.org/lr/video/389987516-Fire-01.mp4"
                                                                                    type="video/mp4" />
                                                                            </video>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="tab-pane container fade" id="OpticalSymmetry">
                                                                    <div class="row">
                                                                        <h4 class="report-sub-heading">OPTICAL SYMMETRY</h4>
                                                                        <div class="vdo-wrap">
                                                                            <video width="100%" id="video-4" class="vidoes"
                                                                                controls="" preload="none">
                                                                                <source
                                                                                    src="https://www.igi.org/lr/video/389987516-ASET Black-02_Optical Symmetry.mp4"
                                                                                    type="video/mp4" />
                                                                            </video>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="tab-pane container fade text-left" id="hna" hidden>

                                                    <h4>Hearts & Arrows Diamonds</h4>

                                                    <p>"Hearts & Arrows" (H&A ) diamonds are cut so precisely that their facet
                                                        reflections overlap when viewed in a reflective scope. Light from above
                                                        becomes white whereas light from the sides becomes colored by filament
                                                        paper. This creates a structured light environment where primary facet
                                                        reflections appear white against a darker background, permitting analysis.
                                                    </p>

                                                    <p>The kaleidoscopic pattern seen face-down in the pavilion resembles "Hearts,"
                                                        and the pattern seen face-up in the crown resembles "Arrows." Hearts &
                                                        Arrows precision is associated with Excellent-Ideal cuts of superior
                                                        quality.</p>

                                                    <img src="../../icon/h-n-a-pavilion-crown-img.jpg"
                                                        class="img-fluid mb-25" />

                                                    <h4>Hearts & Arrows history</h4>

                                                    <p>Japanese diamond cutters in the 1980s were first to produce round brilliant
                                                        diamonds cut so exactly that their facet reflections overlapped in 3D space.
                                                        Those polishers used secret recipes to create these so-called Hearts &
                                                        Arrows patterns. Their techniques spread to other cutting houses and H&A
                                                        diamonds began appearing on several continents by the mid-1990s.</p>

                                                    <h4>Technical mastery</h4>

                                                    <p>Diamonds are the hardest substance known to man, so producing strong H&A
                                                        patterns requires more time in planning, higher labor skill and the
                                                        best-quality cutting tools. The precision and crispness of the patterns
                                                        relies on precise angles in combination with specific facet length, width
                                                        and azimuth. </p>

                                                    <img src="../../icon/h-n-a-technical-mastery-img.jpg"
                                                        class="img-fluid mb-25" />


                                                    <p>Diamonds held to the uppermost level of craftsmanship show extremely precise
                                                        patterns. These exquisite diamonds are valued for the care, precision and
                                                        consistency demonstrated in their cutting as much as any visible effects
                                                        that results from this precision. </p>

                                                    <p>There is also the attractiveness of rarity. Just as D color and Flawless
                                                        clarity are rare, so is the Hearts & Arrows (H&A) level of cut quality. Top
                                                        H&A diamonds are valued for their uniqueness and quality as the ultimate in
                                                        cutting precision.</p>

                                                    <h4>Hearts & Arrows symbolism</h4>

                                                    <p>The eight uniform patterns seen in the top and bottom of Hearts & Arrows
                                                        (H&A) diamonds have a historical association with good fortune and
                                                        spiritualism. The number eight is considered lucky in Asian culture. The
                                                        arrows pattern has been compared to the octagram of the I Ching, the Rinbo
                                                        of Buddhism and the eight-spoked wheel of Dharma, associated with spiritual
                                                        perfection in the Buddhist faith. </p>

                                                    <img src="../../icon/hearts-n-arrows-symbolism-img.jpg"
                                                        class="img-fluid mb-25" width="60%" />

                                                    <h4>Perfection in precision</h4>

                                                    <p>Regardless of spiritual belief, the astonishing achievement of the perfect
                                                        H&A pattern, painstakingly cut into the world's hardest substance, can be
                                                        seen by any admirer of structure. </p>

                                                    <p>In its most fundamental form it symbolizes the diamond cutter's quest for
                                                        perfection in precision and ultimate beauty in a diamond.</p>

                                                </div>

                                                <div class="tab-pane container fade text-left" id="JourneyShiningStar">
                                                    <video controls style={{ width: "100%" }}>
                                                        <source
                                                            src="https://igi-global-video.s3.ap-south-1.amazonaws.com/company/Shinnig-Star-REPORTING-hd.mpeg"
                                                            type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>



                                                <div class="tab-pane container fade text-left" id="AboutAurora">
                                                    <video controls style={{ width: "100%" }}>
                                                        <source
                                                            src="https://igi-global-video.s3.ap-south-1.amazonaws.com/company/About_Aurora.mpeg"
                                                            type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>


                                                <div class="tab-pane container fade text-left" id="thehtml">
                                                </div>


                                                <div class="tab-pane container fade" id="DiamondJourney">


                                                    <div class="row mb-30" id="naturalDiamond" hidden>
                                                        <div class="col-12">
                                                            <h4>Your Diamond's Story</h4>
                                                            <p>Your diamond was forged between one and three billion years ago deep
                                                                beneath the earth’s surface. A powerful process called subduction,
                                                                where ancient land masses moved against each another, produced
                                                                enough pressure, friction and heat that fragments of carbon
                                                                crystallized and grew into our planet’s supply of natural diamonds.
                                                            </p>

                                                            <h4>Volcanic delivery</h4>

                                                            <p>Your diamond began its extraordinary journey toward your hands with a
                                                                volcanic ride to the surface. Some diamonds lodged in the volcanos
                                                                throat. Others were blown clear and distributed over a large area by
                                                                rivers of magma. Rain, erosion, ice-ages and other forces of nature,
                                                                sustained for millions of years, buried some diamonds and released
                                                                others from their mother rock - transporting them hundreds of
                                                                kilometers away.</p>


                                                            <img src="../../icon/volcano-img.jpg"
                                                                class="img-fluid mb-25" />

                                                            <p>Diamonds have been discovered on nearly every continent. The most
                                                                abundant gem-quality deposits are mined in Africa, Russia, Austalia
                                                                and Canada.</p>

                                                            <h4>A history beyond conception</h4>

                                                            <p>Every natural diamond crystal has a history beyond conception and a
                                                                forever-story larger than life. Billions of years old, your diamond
                                                                existed before the dawn of man. Before tribes, clans and nations.
                                                                Every diamond underwent a staggering growth process and made an
                                                                extraordinary journey over countless ages before its discovery. Once
                                                                recovered and sorted, your rough diamond crystal was sent for
                                                                planning and cutting.</p>

                                                            <img src="../../icon/diamond-rocks-img.jpg"
                                                                class="img-fluid mb-25" />

                                                            <h4>Rough to polish</h4>

                                                            <p>Diamond cutting is the art, skill and science of transforming a raw
                                                                crystal into a polished, faceted diamond. As the hardest material
                                                                known to man, cutting and shaping a diamond requires specialized
                                                                knowledge, tools and skill.</p>

                                                            <p>1) Planning: This rough octahedron crystal is studied and its growth
                                                                directions and inclusions are mapped.</p>

                                                            <img src="../../icon/rough-to-polished-1.jpg"
                                                                class="img-fluid mb-25" />

                                                            <p>2) Marking: Once the plan is determined, the crystal is marked for
                                                                primary sawing.</p>

                                                            <img src="../../icon/rough-to-polished-2.jpg"
                                                                class="img-fluid mb-25" />

                                                            <p>3) Sawing: A carbon-fiber blade coated with diamond-grit makes the
                                                                first cut. It will take several hours to saw this crystal into two
                                                                pieces; each of which will become a polished diamond.</p>

                                                            <img src="../../icon/rough-to-polished-3.jpg"
                                                                class="img-fluid mb-25" />

                                                            <p>4) Bruting: The two pieces spin in opposite directions, grinding
                                                                against each other to smooth the edges and create a round outline.
                                                            </p>

                                                            <img src="../../icon/rough-to-polished-4.jpg"
                                                                class="img-fluid mb-25" />

                                                            <p>5) Polishing: A spinning wheel coated with diamond-grit is used for
                                                                blocking, crossworking and brillianteering, the process of placing,
                                                                shaping and smoothing all facets to final polish.</p>

                                                            <img src="../../icon/rough-to-polished-5.jpg"
                                                                class="img-fluid mb-25" />

                                                            <p>6) Inspection: Once finished, the diamond is thoroughly boiled in
                                                                acid and examined to ensure that it meets the producers
                                                                expectations and quality standards.</p>

                                                            <img src="../../icon/diamond-in-hand-img.jpg"
                                                                class="img-fluid mb-25" />

                                                            <h4>Diamond Grading</h4>

                                                            <p>Following the diamond cutting process and prior to being sold loose
                                                                or mounted into jewelry, diamonds are typically submitted to
                                                                gemological institutions such as IGI for quality assessment. Four
                                                                main factors are considered when judging a diamonds quality and
                                                                value. They are the diamonds 4C’s: Carat weight, Color, Clarity and
                                                                Cut.</p>

                                                            <p>IGI screens every diamond using state of the art technologies to
                                                                determine naturally mined, laboratory grown or simulant origin.
                                                                Experienced graduate gemologists conduct further assessment in
                                                                controlled conditions, detailing relevant gemological
                                                                characteristics according to the strictest international system.</p>

                                                            <img src="../../icon/ydc-Diamond-Grading-1.jpg"
                                                                class="img-fluid mb-25" />

                                                            <p>Your diamond is exceptional and distinctive. No two diamonds are
                                                                alike. Yet all diamonds have certain features in common that allow
                                                                experts to compare and evaluate them.</p>

                                                            <h4>Uniquely yours</h4>

                                                            <p>The name diamond is derived from the ancient Greek “adamas” meaning
                                                                unbreakable, untamed and unconquerable. Diamonds have been treasured
                                                                as gemstones ever since they were discovered. In our modern age the
                                                                word “diamond continues to evoke images of elegance, style and
                                                                glamour.</p>

                                                            <p>Diamond sparkles and dazzles. Diamond symbolizes purity and strength.
                                                                And the story of your diamond is uniquely yours.</p>


                                                        </div>
                                                    </div>

                                                    <div class="row mb-30" id="labGrown" hidden>
                                                        <div class="col-12">

                                                            <h4>Your Diamond's Creation</h4>

                                                            <p>
                                                                Your laboratory-grown diamond is chemically identical to every other
                                                                diamond on earth. However, instead of mined diamonds, which formed
                                                                beneath the ground billions of years ago, your diamond was grown in
                                                                controlled laboratory conditions using sophisticated methods of
                                                                chemical synthesis.
                                                            </p>

                                                            <h4>The most modern technology</h4>

                                                            <p>There are two scientific approaches to diamond creation. The Chemical
                                                                Vapor Deposition (CVD) process releases carbon from plasma. This
                                                                involves superheating hydrocarbon gas in a vacuum to between
                                                                3,000-4,000C, at which point the carbon atoms begin to separate from
                                                                their molecular bonds. Those atoms descend and land on a flat wafer
                                                                of previously grown synthetic diamond and grow in vertical layers.
                                                                This substrate is square for jewelry applications but the shape can
                                                                vary for other applications in medicine, communications and
                                                                technology.</p>

                                                            <img src="../../icon/ydc-The-most-modern-technology-1.jpg"
                                                                class="img-fluid mb-20" />
                                                            <p>The High Pressure High Temperature (HPHT) process replicates the
                                                                natural conditions under which diamonds formed 100 miles below the
                                                                surface due to subduction. A carbon source, a diamond seed and a
                                                                metallic catalyst go into an octahedral cell. The cell is placed
                                                                into a massive mechanical press where the contents are heated near
                                                                1,500 C and subjected to staggering pressure. The melting metal
                                                                dissolves the carbon and the pressure causes precipitation to the
                                                                diamond seed, growing a larger diamond. The level of pressure from
                                                                these immense presses has been compared to what you’d experience if
                                                                you balanced a jumbo jet on the tip of your finger.</p>

                                                            <img src="../../icon/ydc-The-most-modern-technology-2.jpg"
                                                                class="img-fluid mb-20" />

                                                            <p>When the growing process has finalized, rough CVD and HPHT crystals
                                                                are sent to diamond cutting centers to be planned and polished.</p>

                                                            <h4>Rough to polish</h4>

                                                            <p>Diamond cutting is the art, skill and science of transforming a raw
                                                                crystal into a polished, faceted diamond. As the hardest material
                                                                known to man, cutting and shaping a diamond requires specialized
                                                                knowledge, tools and skill.</p>

                                                            <p>1) Planning: This rough octahedron crystal is studied and its growth
                                                                directions and inclusions are mapped.</p>

                                                            <img src="../../icon/ydc-Rough-to-polish-1.jpg"
                                                                class="img-fluid mb-20" />

                                                            <p>2) Marking: Once the plan is determined, the crystal is marked for
                                                                primary sawing.</p>

                                                            <img src="../../icon/ydc-Rough-to-polish-2.jpg"
                                                                class="img-fluid mb-20" />

                                                            <p>3) Sawing: A carbon-fiber blade coated with diamond-grit makes the
                                                                first cut. It will take several hours to saw this crystal into two
                                                                pieces; each of which will become a polished diamond.</p>

                                                            <img src="../../icon/ydc-Rough-to-polish-3.jpg"
                                                                class="img-fluid mb-20" />

                                                            <p>4) Bruting: The two pieces spin in opposite directions, grinding
                                                                against each other to smooth the edges and create a round outline.
                                                            </p>

                                                            <img src="../../icon/ydc-Rough-to-polish-4.jpg"
                                                                class="img-fluid mb-20" />

                                                            <p>5) Polishing: A spinning wheel coated with diamond-grit is used for
                                                                blocking, crossworking and brillianteering, the process of placing,
                                                                shaping and smoothing all facets to final polish.</p>

                                                            <img src="../../icon/ydc-Rough-to-polish-5.jpg"
                                                                class="img-fluid mb-20" />

                                                            <p>6) Inspection: Once finished, the diamond is thoroughly boiled in
                                                                acid and examined to ensure that it meets the producers
                                                                expectations and quality standards.</p>

                                                            <img src="../../icon/ydc-Rough-to-polish-6.jpg"
                                                                class="img-fluid mb-20" />

                                                            <h4>Diamond Grading</h4>

                                                            <p>Following the diamond cutting process and prior to being sold loose
                                                                or mounted into jewelry, diamonds are typically submitted to
                                                                gemological institutions such as IGI for quality assessment. Four
                                                                main factors are considered when judging a diamonds quality and
                                                                value. They are the diamonds 4C’s: Carat weight, Color, Clarity and
                                                                Cut.</p>

                                                            <p>IGI screens every diamond using state of the art technologies to
                                                                determine naturally mined, laboratory grown or simulant origin.
                                                                Experienced graduate gemologists conduct further assessment in
                                                                controlled conditions, detailing relevant gemological
                                                                characteristics according to the strictest international system.</p>

                                                            <img src="../../icon/ydc-Diamond-Grading-1.jpg"
                                                                class="img-fluid mb-20" />

                                                            <p>Your diamond is exceptional and distinctive. No two diamonds are
                                                                alike. Yet all diamonds have certain features in common that allow
                                                                experts to compare and evaluate them.</p>

                                                            <h4>Uniquely yours</h4>

                                                            <p>The name diamond is derived from the ancient Greek adamas meaning
                                                                unbreakable, untamed and unconquerable. Diamonds have been treasured
                                                                as gemstones ever since they were discovered. In our modern age the
                                                                word diamond continues to evoke images of elegance, style and
                                                                glamour.</p>

                                                            <p>Diamond sparkles and dazzles. Diamond symbolizes purity and strength.
                                                                And the story of your diamond is uniquely yours.</p>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-30" id="jewellery" hidden>
                                                        <div class="col-12">
                                                            <h4>Forging your jewelry</h4>

                                                            <p>Your jewelry was conceived and fashioned by specialists from start to
                                                                finish. From artistic collaboration and direction to forging and
                                                                cooling to detailed clean-casting and pre-polish. From secure,
                                                                precision stonesetting to hours of final polish, every step was
                                                                deliberate and meticulous.</p>

                                                            <h5>Conception</h5>

                                                            <p>Your piece was designed by visionaries. It may have been an artist
                                                                using hand-sketches or a gemology graduate using modern CAD
                                                                software. Professional jewelry designers must command both the
                                                                basics and complexities of jewelry creation to perfectly balance the
                                                                primary components of aesthetics, durability and cost.</p>

                                                            {/* <!--<img src="https://www.igi.org/assets/images/360/" class="img-fluid mb-20" />--> */}

                                                            <h5>Creation</h5>

                                                            <p>An exact replica of your jewelrys mounting was created in modeling
                                                                wax. Whether hand-carved or rendered with high-precision 3D
                                                                printers, that replica was refined to become a twin of your precious
                                                                item. That twin was placed into a casting flask and encased in
                                                                jewelry-quality investment. The investment hardened to form a
                                                                perfect negative-replica mold, suitable for casting. Its job
                                                                finished, the wax was superheated and removed with vacuum
                                                                technology.</p>

                                                            {/* <!--<img src="https://www.igi.org/assets/images/360/" class="img-fluid mb-20" />--> */}

                                                            <h5>Casting</h5>

                                                            <p>Your mold was taken to the foundry and your precious metal was
                                                                brought to its melting point in a crucible. The metal alloy was
                                                                delivered to the mold in a casting centrifuge with high-pressure
                                                                inert gas, forcing your alloy into sub surface voids and
                                                                facilitating diffusional bonding to promote density, strength and
                                                                durability.</p>

                                                            {/* <!--<img src="https://www.igi.org/assets/images/360/" class="img-fluid mb-20" />--> */}

                                                            <h5>Cleaning</h5>

                                                            <p>When finished and cooled your jewelry was carefully removed from the
                                                                mold. It underwent the rigorous dual-process of clean-casting, where
                                                                excess material was removed, and pre-polish, where the piece was
                                                                thoroughly inspected and verified for fundamental quality and
                                                                integrity.</p>

                                                            {/* <!--<img src="https://www.igi.org/assets/images/360/" class="img-fluid mb-20" />--> */}

                                                            <h5>Culmination</h5>

                                                            <p>Stone-setting and polishing brought your finished jewelry to life.
                                                                The setter meticulously balanced, seated and secured your gemstones,
                                                                and the polisher brought all details of finish to culmination. </p>

                                                            <p>From conception to creation, a talented array of professional
                                                                specialists took deliberate and meticulous steps in the careful
                                                                forging of your jewelry.</p>

                                                            {/* <!--<img src="https://www.igi.org/assets/images/360/" class="img-fluid mb-20" />--> */}

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="tab-pane container fade d-none" id="D360report">
                                                    <p>360 View</p>
                                                    <div id="carouselExampleIndicators"
                                                        class="carousel slide pd-5 res-border-none d-none">
                                                        <div class="carousel-inner">
                                                            <div class="carousel-item active">

                                                            </div>

                                                        </div>

                                                        <ol class="carousel-indicators">
                                                            <li data-target="#carouselExampleIndicators" data-slide-to="0"
                                                                class="slide_1">
                                                                <img src="../../icon/360-degrees.png"
                                                                    class="img_frame d-block img-fluid"
                                                                    alt="IGI LABORATORY REPORTS" />
                                                                <strong>&nbsp;</strong>
                                                            </li>
                                                        </ol>

                                                        <a class="carousel-control-prev" href="#carouselExampleIndicators"
                                                            role="button" data-slide="prev">
                                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span class="sr-only">Previous</span>
                                                        </a>

                                                        <a class="carousel-control-next" href="#carouselExampleIndicators"
                                                            role="button" data-slide="next">
                                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span class="sr-only">Next</span>
                                                        </a>
                                                    </div>
                                                </div>

                                                {/* <!--<div class="tab-pane container fade" id="LightPerfomance">
            <div class="col-12">
            <div class="row mb-30">
                <div class="col-12">
                    <img src="https://www.igi.org/assets/images/360/report_smalli.jpg" class="img-fluid">
                </div>
            </div>
            </div>
        </div>--> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 order-md-last">

                                        {/* <!--
    <button class="btn show-report-btn" data-toggle="modal" data-target="#ViewReportigi">Show Report</button>
    --> */}

                                        {/* <!--<div class="report-thumb">
        <a href="javascript:void(0)" class="btn pdf-view-btn" data-toggle="modal" data-target="#ViewReportigi">
                Zoom <i class="fa fa-search-plus" aria-hidden="true"></i>
            </a>
            <a href="#" class="middle_pdf_preview_link preview-img-size">
            <img src="https://www.igi.org/assets/images/360/white-img.jpg" class="hover-shadow cursor img-fluid middle_pdf_preview">
                 <div class="middle_pdf_preview_embed_iframe"></div>

            </a>

        <div class="pdf-download-btn view_pdf mt-0"></div>
    </div>--> */}





                                        <div class="footer-logo-ny mb-20 " hidden>

                                            <img id="img_url" src="#" class="img-fluid" alt="IGI logo" />
                                        </div>

                                        <a href="#" target="_blank" hidden id="Url_Right" class="Url_Right">


                                            <p class="text-dark">
                                                To view more information on the pre-existing inscription, please <u>click here</u>
                                                and enter the inscription number. </p>
                                        </a>


                                        <div class="contact">

                                            <h5 class="text-center mt-0"> Europe</h5>
                                            {/* <Button
                                    onClick={() => setOpen(!open)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={open}
                                >
                                    click
                                </Button> */}
                                            {/* <Collapse in={open}>
                                    <div id="example-collapse-text">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                                        terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
                                        labore wes anderson cred nesciunt sapiente ea proident.
                                    </div>
                                </Collapse> */}

                                            <div class="accordion course_schedule_accordion mb-30 contact-accordion"
                                                id="addresscity0">
                                                <div class="card">
                                                    <div class="card-header" id="cardAntwerp">
                                                        <a
                                                            onClick={() => setOpen(!open)}
                                                            aria-controls="example-collapse-text"
                                                            aria-expanded={open}

                                                            class="btn btn-link collapsed"
                                                            data-toggle="collapse"
                                                            data-target="#divAntwerp"
                                                        //  aria-expanded="false"
                                                        // aria-controls="divAntwerp"
                                                        >
                                                            <h3>
                                                                Antwerp <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divAntwerp" class="collapse" aria-labelledby="div58"
                                                        data-parent="#cardAntwerp" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <Collapse in={open}>
                                                                    <div id="example-collapse-text">
                                                                        Schupstraat 1<br />
                                                                        2018 Antwerp<br /> BELGIUM<br />
                                                                        Phone : +32 3 401 08 88<br />
                                                                        Fax : +32 3 232 07 58<br /> Email : <a
                                                                            href='mailto:info@igi.org'>info@igi.org</a><br /> </div>

                                                                </Collapse>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardItaly">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divItaly" aria-expanded="false" aria-controls="divItaly">
                                                            <h3 class="">
                                                                Italy <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divItaly" class="collapse" aria-labelledby="div59"
                                                        data-parent="#cardItaly" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <strong>Coordinamento</strong><br />
                                                                <br />
                                                                Phone : 339 7216428<br />
                                                                Email : <a href='mailto:italy@igi.org '>italy@igi.org </a><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divItaly" class="collapse" aria-labelledby="div59"
                                                        data-parent="#cardItaly" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <strong>Ascoli Piceno</strong><br />
                                                                Istituto Piceno di Gemmologia<br />Via della Trib Fabia
                                                                23<br />63100 Ascoli Piceno<br /> Italia<br />
                                                                Phone : <br />
                                                                Email : <a
                                                                    href='mailto:a.sita@igi-italy.it'>a.sita@igi-italy.it</a><br />
                                                                Mobile : +39 339 7216428<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divItaly" class="collapse" aria-labelledby="div59"
                                                        data-parent="#cardItaly" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <strong>Cavalese</strong><br />
                                                                Gemmarum Lapidator Srl <br />Localit Podera 16 <br />38033 -
                                                                Cavalese (TN)<br /> Italia<br />
                                                                Phone : +39 0462 342662<br />
                                                                Email : <a
                                                                    href='mailto:g.lombardi@igi-italy.it'>g.lombardi@igi-italy.it</a><br />
                                                                Mobile : +39 0462 232959<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divItaly" class="collapse" aria-labelledby="div59"
                                                        data-parent="#cardItaly" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <strong>Marcianise</strong><br />
                                                                Gem-Tech <br />Centro Orafo Oromare S.C.P.A. <br />Strada
                                                                Provinciale 22, Km 1.750 <br />81025 - Marcianise (CE)<br />
                                                                Italia<br />
                                                                Phone : <br />
                                                                Email : <a
                                                                    href='mailto:f.sequino@igi-italy.it'>f.sequino@igi-italy.it</a><br />
                                                                Mobile : +39 348 3657121<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divItaly" class="collapse" aria-labelledby="div59"
                                                        data-parent="#cardItaly" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <strong>Roma</strong><br />
                                                                IGN - Istituto Gemmologico Nazionale <br />Via di S. Sebastianello 6
                                                                <br />00187 - Roma<br /> Italia<br />
                                                                Phone : +39 06 6783056<br />
                                                                Fax : +39 06 6783056<br /> Email : <a
                                                                    href='mailto:f.butini@igi-italy.it'>f.butini@igi-italy.it</a><br />
                                                                Toll Free No : 800 134 879<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divItaly" class="collapse" aria-labelledby="div59"
                                                        data-parent="#cardItaly" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <strong>Trecastagni</strong><br />
                                                                IGMG - Istituto Gemmologico Magna Graecia <br />Via V. Alfieri 12
                                                                <br />95039 - Trecastagni (CT)<br /> Italia<br />
                                                                Phone : +39 095 7807625<br />
                                                                Email : <a
                                                                    href='mailto:c.russo@igi-italy.it'>c.russo@igi-italy.it</a><br />
                                                                Mobile : +39 338 8010331<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h5 class="text-center mt-0"> Middle East</h5>
                                            <div class="accordion course_schedule_accordion mb-30 contact-accordion"
                                                id="addresscity1">
                                                <div class="card">
                                                    <div class="card-header" id="cardTel_Aviv">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divTel_Aviv" aria-expanded="false"
                                                            aria-controls="divTel_Aviv">
                                                            <h3 class="">
                                                                Tel Aviv <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divTel_Aviv" class="collapse" aria-labelledby="div60"
                                                        data-parent="#cardTel_Aviv" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Yahalom Building, Room 3178-3182 Tuval 21 52520 Ramat Gan<br />
                                                                ISRAEL<br />
                                                                Phone : +972 3 756 4200<br />
                                                                Fax : +972 3 613 7231<br /> Email : <a
                                                                    href='mailto:israel@igi.org'>israel@igi.org</a><br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardDubai">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divDubai" aria-expanded="false" aria-controls="divDubai">
                                                            <h3 class="">
                                                                Dubai <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divDubai" class="collapse" aria-labelledby="div61"
                                                        data-parent="#cardDubai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Office Unit No 27-A, B, C & G, <br /> Almas Tower,<br />
                                                                Jumeriah Lake Towers,<br /> Dubai, U.A.E<br />
                                                                Phone : + 971 4 450 8027<br />
                                                                Fax : + 971 4 450 8023<br /> Email : <a
                                                                    href='mailto:dubai@igi.org'>dubai@igi.org</a><br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="text-center mt-0"> North America</h5>
                                            <div class="accordion course_schedule_accordion mb-30 contact-accordion"
                                                id="addresscity2">
                                                <div class="card">
                                                    <div class="card-header" id="cardNew_York">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divNew_York" aria-expanded="false"
                                                            aria-controls="divNew_York">
                                                            <h3 class="">
                                                                New York <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divNew_York" class="collapse" aria-labelledby="div62"
                                                        data-parent="#cardNew_York" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                551 Fifth Avenue, 7th floor<br />
                                                                New York, NY 10176<br /> USA<br />
                                                                Phone : +1 212 753 7100<br />
                                                                Fax : +1 212 753 7759<br /> Email : <a
                                                                    href='mailto:newyork@igi.org'>newyork@igi.org</a><br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardLos_Angeles">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divLos_Angeles" aria-expanded="false"
                                                            aria-controls="divLos_Angeles">
                                                            <h3 class="">
                                                                Los Angeles <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divLos_Angeles" class="collapse" aria-labelledby="div63"
                                                        data-parent="#cardLos_Angeles" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Suite 1200 550 South Hill Street<br />
                                                                Los Angeles, CA 90013<br /> USA<br />
                                                                Phone : +1 213 955 0008<br />
                                                                Fax : +1 213 955 8060<br /> Email : <a
                                                                    href='mailto:losangeles@igi.org'>losangeles@igi.org</a><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="text-center mt-0"> East Asia</h5>
                                            <div class="accordion course_schedule_accordion mb-30 contact-accordion"
                                                id="addresscity3">
                                                <div class="card">
                                                    <div class="card-header" id="cardHong_Kong">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divHong_Kong" aria-expanded="false"
                                                            aria-controls="divHong_Kong">
                                                            <h3 class="">
                                                                Hong Kong <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divHong_Kong" class="collapse" aria-labelledby="div64"
                                                        data-parent="#cardHong_Kong" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Unit 705, 7th Floor, Fu Hang Industrial Building,<br />
                                                                No. 1 Hok Yuen Street East, Hunghom.<br /> HONG KONG<br />
                                                                Phone : +852 2522 9880<br />
                                                                Fax : +852 2522 9887<br /> Email : <a
                                                                    href='mailto:hk@igi.org'>hk@igi.org</a><br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardShanghai">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divShanghai" aria-expanded="false"
                                                            aria-controls="divShanghai">
                                                            <h3 class="">
                                                                Shanghai <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divShanghai" class="collapse" aria-labelledby="div65"
                                                        data-parent="#cardShanghai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                706-707,Block S1, BFC <br />
                                                                600 zhongshan No.2 Road(E) <br /> Huangpu, Shanghai<br /> CHINA<br />
                                                                Phone : +86 21 3876 0730<br />
                                                                Fax : +86 21 3876 0731<br /> Email : <a
                                                                    href='mailto:shanghai@igi.org'>shanghai@igi.org</a><br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardShenzhen">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divShenzhen" aria-expanded="false"
                                                            aria-controls="divShenzhen">
                                                            <h3 class="">
                                                                Shenzhen <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divShenzhen" class="collapse" aria-labelledby="div98"
                                                        data-parent="#cardShenzhen" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Room 1501A, Jinzhan Gold Plaza, 20 Shuibei No.1 Road, <br />
                                                                Cuijin Community, Cuizhu Street, Luohu District, Shenzhen<br />
                                                                China<br />
                                                                Phone : +86 755 25508699<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardBangkok">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divBangkok" aria-expanded="false"
                                                            aria-controls="divBangkok">
                                                            <h3 class="">
                                                                Bangkok <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divBangkok" class="collapse" aria-labelledby="div66"
                                                        data-parent="#cardBangkok" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                BGI Building, <br /> 9 Soi Charoen Krung<br />
                                                                36, New Road, Bangkok 10500<br /> THAILAND<br />
                                                                Phone : +66 2 630 6726 / +66 2 630 6727<br />
                                                                Fax : +66 2 630 6728<br /> Email : <a href='mailto:thailand@igi.org'>thailand@igi.org</a><br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="text-center mt-0"> South Asia</h5>
                                            <div class="accordion course_schedule_accordion mb-30 contact-accordion"
                                                id="addresscity4">
                                                <div class="card">
                                                    <div class="card-header" id="cardMumbai">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divMumbai" aria-expanded="false"
                                                            aria-controls="divMumbai">
                                                            <h3 class="">
                                                                Mumbai <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divMumbai" class="collapse" aria-labelledby="div68"
                                                        data-parent="#cardMumbai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <b>Head Office</b><br />
                                                                702, The Capital Bandra Kurla Complex, Bandra (E), Mumbai 400
                                                                051.<br /> India<br />
                                                                Phone : +91 22 4035 2550<br />
                                                                Email : <a href='mailto:india@igi.org'>india@igi.org</a><br /> For
                                                                Education : indiaeducation@igi.org<br /> Toll Free No :
                                                                18001028610<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divMumbai" class="collapse" aria-labelledby="div68"
                                                        data-parent="#cardMumbai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <b>Laxmi Tower - IGI Education
                                                                    (School Of Gemology)
                                                                </b><br />
                                                                Office no. 302, 3rd Floor, A-wing, Laxmi Tower, BKC Mumbai 400 051.
                                                                <br /> India<br />
                                                                Phone : +91-022 4973 6065 / 64<br />
                                                                Email : <a
                                                                    href='mailto:indiaeducation@igi.org'>indiaeducation@igi.org</a><br />
                                                                For Certification : india@igi.org<br /> For Education :
                                                                indiaeducation@igi.org<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divMumbai" class="collapse" aria-labelledby="div68"
                                                        data-parent="#cardMumbai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <b>Seepz</b><br />
                                                                Seepz ++ Building No.1 Gala no.3, MIDC Andheri (E), Mumbai 400
                                                                096.<br /> India<br />
                                                                Phone : +91 22-2829 1006<br />
                                                                Fax : +91 22-2829 1009<br /> For Certification : speezdata@igi.org<br />
                                                                For Education : indiaeducation@igi.org<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divMumbai" class="collapse" aria-labelledby="div68"
                                                        data-parent="#cardMumbai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <b>Girgaon</b><br />
                                                                1109, Floor-11, Plot-CS 1487, Prasad Chamber, TATA Road No 2, Roxy
                                                                Cinema Opera House, Girgaon, Mumbai-400004<br /> India<br />
                                                                Phone : +91 22-4926 2550<br />
                                                                For Certification : india.jewelry@igi.org<br /> For Education :
                                                                indiaeducation@igi.org<br /> Toll Free No : 18001028610<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divMumbai" class="collapse" aria-labelledby="div68"
                                                        data-parent="#cardMumbai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <b>MIDC</b><br />
                                                                Seepz ++ D-3, 1st Floor, Udyog Sadan, MIDC, Opp, Speez Gate No.1
                                                                Andheri (E), Mumbai 4000 096.<br /> India<br />
                                                                Phone : +91 22-4232 3555<br />
                                                                Fax : +91 22-4232 3556<br /> For Certification : midc@igi.org<br /> For
                                                                Education : indiaeducation@igi.org<br /> Toll Free No :
                                                                18001028610<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="divMumbai" class="collapse" aria-labelledby="div68"
                                                        data-parent="#cardMumbai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                <b>Zaveri Bazar</b><br />
                                                                915/916, 9th Floor, Jewel World Cotton Exchange Bldg, Junction
                                                                Zaveri Bazar/Kalbadevi Road, Opp Surti Hotel, Mumbai:-400002,
                                                                India.<br /> India<br />
                                                                Phone : <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardNew_Delhi">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divNew_Delhi" aria-expanded="false"
                                                            aria-controls="divNew_Delhi">
                                                            <h3 class="">
                                                                New Delhi <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divNew_Delhi" class="collapse" aria-labelledby="div69"
                                                        data-parent="#cardNew_Delhi" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Keltron Chambers, 04th Floor,<br /> Arya Samaj Road, Karol Bagh, <br />
                                                                New Delhi, Delhi 110 005<br /> India<br />
                                                                Phone : +91 11 4501 2551<br />
                                                                Fax : +91 11 4501 2552<br /> For Certification : newdelhi@igi.org<br />
                                                                For Education : delhiedu@igi.org<br /> Toll Free No : 18001028610<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardHyderabad">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divHyderabad" aria-expanded="false"
                                                            aria-controls="divHyderabad">
                                                            <h3 class="">
                                                                Hyderabad <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divHyderabad" class="collapse" aria-labelledby="div70"
                                                        data-parent="#cardHyderabad" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                102, Regency House, Somajiguda,<br />
                                                                Hyderabad  500082. Telangana<br /> India<br />
                                                                Phone : +91 40 66172550<br />
                                                                Fax : +91 40 66172551<br /> For Certification : hyderabad@igi.org<br />
                                                                For Education : hyderabadedu@igi.org<br /> Toll Free No :
                                                                18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardKolkata">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divKolkata" aria-expanded="false"
                                                            aria-controls="divKolkata">
                                                            <h3 class="">
                                                                Kolkata <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divKolkata" class="collapse" aria-labelledby="div71"
                                                        data-parent="#cardKolkata" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                708/709, Fort Knox, 6 Camac Street<br />
                                                                Kolkata, West Bengal 700017<br /> India<br />
                                                                Phone : +91 33 2290 2600<br />
                                                                Fax : +91 33 2290 2602<br /> For Certification : kolkata@igi.org<br />
                                                                For Education : kolkataedu@igi.org<br /> Toll Free No :
                                                                18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardSurat">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divSurat" aria-expanded="false" aria-controls="divSurat">
                                                            <h3 class="">
                                                                Surat <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divSurat" class="collapse" aria-labelledby="div72"
                                                        data-parent="#cardSurat" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Diamond World, Tower C-101/104, 1st Floor,<br />
                                                                Mangadh Chowk, Varachha Road, Surat - 395 006<br /> India<br />
                                                                Phone : +91 261 4020 550<br />
                                                                Fax : +91 261 4020 551<br /> For Certification : surat@igi.org<br /> For
                                                                Education : suratedu@igi.org<br /> Toll Free No : 18001028610<br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardAhmedabad">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divAhmedabad" aria-expanded="false"
                                                            aria-controls="divAhmedabad">
                                                            <h3 class="">
                                                                Ahmedabad <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divAhmedabad" class="collapse" aria-labelledby="div73"
                                                        data-parent="#cardAhmedabad" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                405, 4th Floor<br /> 323 Corporate Park <br />Girish cold drink cross
                                                                roads<br />
                                                                Navrangpura Ahmedabad - 380009<br /> India<br />
                                                                Phone : +91 79 4039 3550<br />
                                                                For Certification : ahmedabad@igi.org<br /> For Education :
                                                                indiaeducation@igi.org<br /> Toll Free No : 18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardThrissur">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divThrissur" aria-expanded="false"
                                                            aria-controls="divThrissur">
                                                            <h3 class="">
                                                                Thrissur <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divThrissur" class="collapse" aria-labelledby="div74"
                                                        data-parent="#cardThrissur" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Centre Point, 2nd Floor, M. G. Road,<br />
                                                                Thrissur 680 004<br /> India<br />
                                                                Phone : +91 92872 22550<br />
                                                                Fax : +91 4872420 337<br /> For Certification : thrissur@igi.org<br />
                                                                For Education : indiaeducation@igi.org<br /> Toll Free No :
                                                                18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardChennai">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divChennai" aria-expanded="false"
                                                            aria-controls="divChennai">
                                                            <h3 class="">
                                                                Chennai <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divChennai" class="collapse" aria-labelledby="div75"
                                                        data-parent="#cardChennai" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                3C, Kences Tower, No.1, Ramakrishna Street,<br />
                                                                T. Nagar, Chennai-600017<br /> India<br />
                                                                Phone : +91 44 4350 2570 / 71<br />
                                                                Fax : +91 44 4350 2572<br /> For Certification : chennai@igi.org<br />
                                                                For Education : chennaiedu@igi.org<br /> Toll Free No :
                                                                18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardJaipur">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divJaipur" aria-expanded="false"
                                                            aria-controls="divJaipur">
                                                            <h3 class="">
                                                                Jaipur <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divJaipur" class="collapse" aria-labelledby="div76"
                                                        data-parent="#cardJaipur" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                International Gemological Institute (I) Pvt. Ltd.<br />
                                                                2nd floor, Shree Balaji Plaza, Bhagwan Das Road, St. Xavier`s
                                                                School, C-Scheme, Jaipur - 302001.<br /> India<br />
                                                                Phone : 0141-4627 000/4025 179 <br />
                                                                For Certification : jaipur@igi.org<br /> For Education :
                                                                jaipuredu@igi.org<br /> Toll Free No : 18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardBangalore">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divBangalore" aria-expanded="false"
                                                            aria-controls="divBangalore">
                                                            <h3 class="">
                                                                Bangalore <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divBangalore" class="collapse" aria-labelledby="div77"
                                                        data-parent="#cardBangalore" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                International Gemological Institute No. 136, Cears<br />
                                                                1st Floor, Office No. 101, <br /> Residency Road, Bangalore -
                                                                560025<br /> India<br />
                                                                Phone : +91 80 43750245/ 6<br />
                                                                For Education : bangaloreedu@igi.org<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardIndore">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divIndore" aria-expanded="false"
                                                            aria-controls="divIndore">
                                                            <h3 class="">
                                                                Indore <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divIndore" class="collapse" aria-labelledby="div109"
                                                        data-parent="#cardIndore" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                110 111, First Floor, Vidhyapati Building, 17, Dr. Roshan Singh
                                                                Bhandari Marg, Race Course Road,<br />
                                                                Opposite Basketball Club, Near Narayan Kothi, Janjeerwala Square,
                                                                New Palasia, Indore  452001, Madhya Pradesh<br /> India<br />
                                                                Phone : +91 07314960455<br />
                                                                For Certification : indore@igi.org<br /> For Education :
                                                                indiaeducation@igi.org<br /> Toll Free No : 18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardChandigarh">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divChandigarh" aria-expanded="false"
                                                            aria-controls="divChandigarh">
                                                            <h3 class="">
                                                                Chandigarh <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divChandigarh" class="collapse" aria-labelledby="div113"
                                                        data-parent="#cardChandigarh" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                SCO-45, First Floor, Sector-21C<br />
                                                                Chandigarh-160022<br /> India<br />
                                                                Phone : 0172-4122001<br />
                                                                Email : <a
                                                                    href='mailto:chandigarh@igi.org'>chandigarh@igi.org</a><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="cardCoimbatore">
                                                        <a class="btn btn-link collapsed" data-toggle="collapse"
                                                            data-target="#divCoimbatore" aria-expanded="false"
                                                            aria-controls="divCoimbatore">
                                                            <h3 class="">
                                                                Coimbatore <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                            </h3>
                                                        </a>
                                                    </div>
                                                    <div id="divCoimbatore" class="collapse" aria-labelledby="div114"
                                                        data-parent="#cardCoimbatore" >
                                                        <div class="card-body">
                                                            <div class="address">
                                                                Gowtham Arcade, 2nd Floor, 208 T.V. Samy Road (East),<br />
                                                                R S Puram, Coimbatore, Tamil Nadu 641002.<br /> India<br />
                                                                Phone : +91 4224970589<br />
                                                                For Certification : coimbatore@igi.org<br /> For Education :
                                                                indiaeducation@igi.org<br /> Toll Free No : 18001028610<br /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>



                </section>


          <div style={{width: "104%"}}>
          <section className="we_train_best" style={{ backgroundImage: `url(${wetrain})` }}>
                    <div className="overlay">
                        <div className="container">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <a href="#">
                                    <h4 className="parallax_head mb-40">We Train the Best Gemologists in the World</h4>
                                </a>
                                <a href="#" className="white-btn-transparent">Enroll Now</a>
                            </div>
                        </div>
                    </div></section>


                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1 mb-30">
                                <div class="footer_logo_area">
                                    <img src={whitelogo}
                                        alt="IGI - International Gemological Institute"
                                        class="img-fluid mx-auto d-block footer_logo" />
                                    <div class="footer_slogan">
                                        <span>IGI's supreme position in the gemological world is no coincidence. It is the result of
                                            continuous research, support and synergy with professionals and consumers alike.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1 mb-30">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div class="footer_menu">
                                            <h4 class="footer_title">ABOUT US</h4>
                                            <a href="#" class="footer_link">About IGI<br /></a>
                                            <a href="#" class="footer_link">PressRoom<br /></a>
                                            <a href="#" class="footer_link">Timeline<br /></a>
                                            <a href="#" class="footer_link">Affiliations<br /></a>
                                            <a href="#" class="footer_link">Importance of Certification<br /></a>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div class="footer_menu">
                                            <h4 class="footer_title">REPORTS</h4>
                                            <a href="#" class="footer_link">Our Expertise<br /></a>
                                            <a href="#" class="footer_link">How to Read a Report<br /></a>
                                            <a href="#" class="footer_link">Diamond Reports<br /></a>
                                            <a href="#" class="footer_link">Jewelry Report<br /></a>
                                            <a href="#" class="footer_link">Colored Stone Report<br /></a>
                                            <a href="#" class="footer_link">Screening Services<br /></a>
                                            <a href="#" class="footer_link">Sorting Services<br /></a>
                                            <a href="#" class="footer_link">Security Seal<br /></a>
                                            <a href="#" class="footer_link">Laserscribe<br /></a>
                                            <a href="#" class="footer_link">Verify Your Report<br /></a>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div class="footer_menu">
                                            <h4 class="footer_title">EDUCATION</h4>
                                            <a href="#" class="footer_link">Gemological Education<br /></a>
                                            <a href="#" class="footer_link">Courses<br /></a>
                                            <a href="#" class="footer_link">Course Schedules<br /></a>
                                            <a href="#" class="footer_link">E-Learning<br /></a>
                                            <a href="#" class="footer_link">Tuition Fees<br /></a>
                                            <a href="#" class="footer_link">Enrollment Form<br /></a>
                                            <a href="#" class="footer_link">Enquiry Form<br /></a>
                                            <a href="#" class="footer_link">Gemstone Articles<br /></a>
                                            <a href="#" class="footer_link">Retail Support<br /></a>
                                            <a href="#" class="footer_link">Learn with Videos<br /></a>
                                            <a href="#" class="footer_link">Verify a Diploma<br /></a>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div class="footer_menu">
                                            <h4 class="footer_title">Other</h4>
                                            {/* <!--  <a href="https://www.igi.org/reports/verify_your_report" class="footer_link">Verify your Report</a> --> */}
                                            <a href="#" class="footer_link">Press</a>
                                            <a href="#" class="footer_link">Shows</a>
                                            <a href="#" class="footer_link">Consumer Services</a>
                                            <a href="#" class="footer_link">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1">
                                <div class="footer_copy">
                                    <div class="row">
                                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                            <div>
                                                <p>2020 &copy; IGI Ltd. All rights reserved</p>
                                                <div class="footer-powered" style={{ float: "left" }}>
                                                    <a href="#" target="_blank" class="footer_link_terms padder">Terms &
                                                        Conditions</a>
                                                    &nbsp;|&nbsp;
                                                    <a href="#" target="_blank" class="footer_link_terms padder">Privacy policy</a>
                                                    &nbsp;|&nbsp;
                                                    <a href="#" target="_blank" class="footer_link_terms padder">Terms of use</a>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                            <p align="center"><a href="#" > 沪 ICP 备
                                                19000101 号 -1</a></p>
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="social_btn">
                                                Follow IGI <a href="https://www.facebook.com/InternationalGemologicalInstitute/"
                                                    target="_blank">
                                                    <i class="fa fa-facebook-official" aria-hidden="true"></i>
                                                </a>
                                                <a href="https://www.instagram.com/igiworldwide/" target="_blank">
                                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                            <div class="footer-powered">
                                                Powered by <a href="#" target="_blank"
                                                    rel="nofollow">Tecogis</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>

                <div id="verifyReoprt" class="verify-form" style={{ left: visible }}>
                    <form id="verifyReportForm" data-name="Email Form 3" action="#" method="POST">
                        <input type="text" class="verify-form-inp" placeholder="Verify your Report" name="sumid" onChange={(e) => { handlechange(e); }} required />
                        <input type="button" onClick={dataSubmit} value="Submit" class="theme_btn_o_w" />
                        {/* <button type="button" onClick={dataSubmit} class="btn btn-info"><i class="fa fa-search" aria-hidden="true"></i></button> */}
                    </form>
                    <span class="search-icn" onClick={Ishide}>
                        <i id="SearchIcon" class="scroll-Report fa fa-times" aria-hidden="true"></i>

                    </span>
                </div>
          </div>


                <Modal size="xl" show={show} onHide={handleClose}>
                    {/* <Modal.Header >
                    <Modal.Title></Modal.Title> 

                </Modal.Header> */}
                    <Modal.Body><a onClick={handleClose} style={{ float: "right" }}>
                        <i class="fa fa-times cros" aria-hidden="true"></i></a>
                        <img src={"http://api.igi-org.in/"+ getdata.softcopy} alt='image' className="imgstyle" />
                    </Modal.Body>
                </Modal>

                <Modal show={show2} onHide={handleClose2}>
                    {/* <Modal.Header >
                    <Modal.Title></Modal.Title> 

                </Modal.Header> */}
                    <Modal.Body><a onClick={handleClose2} style={{ float: "right" }}>
                        <i class="fa fa-times cros" aria-hidden="true"></i></a>

                        <a class="a2a_dd" href='https%3A%2F%2Figi.org.in%2Freports%2Fverify-your-report%2F507%23&title=Verify%20Your%20Report%20%7C%20Verify%20Your%20Diamond%20Online%20%7C%20IGI' />
                    </Modal.Body>
                </Modal>
            </>)
    }

    //   const imgs="http://103.171.181.73:5011/" + getdata.softcopy

    return (
        <>
            {spinner && (
                <div className='loadingstyle mobload span'>
                    <img src={whitelogo} className="img-fluid mx-auto d-block footer_logo imgmar bounce" />

                    <h5 className='text-white' style={{ marginTop: "-25px", fontSize: "20px" }}>Loading <span
                        data-text=" ..." style={{ letterSpacing: "2px" }}> </span></h5>
                </div>
            )}
            {/* <Header2 /> */}

            {webshow == true && (
                displayFunction()
            )}

            {webshow == false && (
                displaymaintenceFunction()
            )}
        </>
    )
}

export default Main2